
import { defineComponent } from '@nuxtjs/composition-api'
import { useUiNotification } from '~/composables'

import Notification from '~/components/Notification.vue'

export default defineComponent({
  name: 'NotificationBar',
  components: {
    Notification
  },
  setup() {
    const { notifications } = useUiNotification();

    return {
      notifications
    };
  },
});
